import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Button, Show } from "@/components";
import { useRoles } from "@/hooks";
import _ from "lodash";
import { useUrlState } from "@/utils";
import CreateRoleModal from "../components/modals/create-role";
import { useAppNavigate, useCurrentUser, useCurrentUserPermissions } from "@/apollo/cache/auth";
import UpdateRoleModal from "../components/modals/update-role";
import DefaultLoader from "@/components/loaders/default-loader";
import { hasPermission, PERMISSIONS } from "@/permissions";
import illustration from "@/assets/images/illustration.jpg";

type Props = {};

const UserRoles = (props: Props) => {
    const employer = useCurrentUser();
    const currentUserPermissions = useCurrentUserPermissions();
    const [modal, setModal] = useUrlState("modal");
    const [search, setSearch] = useUrlState("search");

    const { roles, loading } = useRoles({
        variables: { filter: { companyId: employer?.companyId ? { eq: employer?.companyId } : undefined, name: !_.isEmpty(search) ? { like: search } : undefined } },
    });
    const navigate = useAppNavigate();
    return (
        <>
            <div>
                <div className="flex justify-between  my-6 items-center space-x-2">
                    <div className="ring-1 ring-gray-300  px-3 w-96 h-9 flex items-center gap-2 rounded-md shadow">
                        <MagnifyingGlassIcon className="w-3 h-3 text-gray-500" aria-hidden="true" />
                        <input type="text" placeholder="Search roles" className="w-full h-full outline-none text-sm " value={search} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                    <div>
                        <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_ROLE_CREATE)}>
                            <Button size="sm" onClick={() => setModal("createRole")}>
                                <span className="text-xs whitespace-nowrap hidden md:flex">Add New User Role</span>
                                <span className="text-xs whitespace-nowrap flex md:hidden">Add Role</span>
                            </Button>
                        </Show>
                    </div>
                </div>
                {loading ? (
                    <div className="w-full h-full">
                        <DefaultLoader />
                    </div>
                ) : !loading && _.isEmpty(roles) ? (
                    <div className="flex flex-col justify-center items-center space-y-1">
                        <img src={illustration} alt="empty-illustration" className="h-24 w-24" />
                        <h6 className="text-lg font-semibold leading-5 mt-2">No Roles Found</h6>
                        <p className="text-xs text-gray-500 ">You have no roles available</p>
                    </div>
                ) : (
                    <div className="mt-5 border rounded-md w-full overflow-scroll overflow-x-scroll flex">
                        <div className="flex-shrink-0  min-w-full">
                            <table className="divide-y divide-gray-200  w-full ">
                                <thead>
                                    <tr className="bg-[#F9FAFB] h-10 w-full space-x-3">
                                        <th scope="col" className="text-left pl-5 text-xs h-full font-medium text-gray-600 w-40 flex-shrink-0 ">
                                            Code
                                        </th>
                                        <th scope="col" className="text-left text-xs h-full font-medium text-gray-600 w-40 flex-shrink-0">
                                            Name
                                        </th>
                                        <th scope="col" className="text-left text-xs h-full font-medium text-gray-600 w-40 flex-shrink-0">
                                            Description
                                        </th>
                                        <th scope="col" className="text-left text-xs h-full font-medium text-gray-600 w-20 flex-shrink-0">
                                            Permissions Count
                                        </th>
                                        <th scope="col" className="text-left text-xs h-full font-medium text-gray-600 w-20 flex-shrink-0">
                                            Users Count
                                        </th>

                                        <th scope="col" className="text-left text-xs h-full font-medium text-gray-600 w-20 flex-shrink-0">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white w-full">
                                    {roles?.map((role) => (
                                        <tr key={role?.id} className="">
                                            <td className="whitespace-nowrap text-xs text-start w-40 pl-5">{role?.code}</td>
                                            <td className="whitespace-nowrap text-xs text-start w-40">{role?.name}</td>
                                            <td className=" text-xs text-start w-40 max-w-40 line-clamp-2">{role?.description}</td>
                                            <td className="whitespace-nowrap text-xs text-start w-40">{role?.permissions?.length || 0}</td>
                                            <td className="whitespace-nowrap text-xs text-start w-40">{role?.userCount || 0}</td>
                                            <td className="relative  whitespace-nowrap py-5 text-left text-xs font-semibold w-24 flex space-x-3">
                                                <Show if={hasPermission(currentUserPermissions, PERMISSIONS.SETTING_ROLE_UPDATE)}>
                                                    <button
                                                        onClick={() =>
                                                            navigate({
                                                                search(prev) {
                                                                    return { ...prev, modal: "editRole", id: role?.id || undefined };
                                                                },
                                                            })
                                                        }
                                                        className="text-primary hover:text-green-600 ">
                                                        Edit
                                                    </button>
                                                </Show>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
            {modal === "createRole" && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_ROLE_CREATE) && (
                <CreateRoleModal
                    open={modal === "createRole"}
                    setOpen={() =>
                        navigate({
                            search(prev) {
                                return { ...prev, modal: undefined };
                            },
                        })
                    }
                />
            )}
            {modal === "editRole" && hasPermission(currentUserPermissions, PERMISSIONS.SETTING_ROLE_UPDATE) && (
                <UpdateRoleModal
                    open={modal === "editRole"}
                    setOpen={() =>
                        navigate({
                            search(prev) {
                                return { ...prev, modal: undefined, id: undefined };
                            },
                        })
                    }
                />
            )}
        </>
    );
};

export default UserRoles;
