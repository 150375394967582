"use client";

import { clearAuth, useAppNavigate, useCurrentUser } from "@/apollo/cache/auth";
import {
    ResetPasswordAfterCodeVerificationMutation,
    ResetPasswordAfterCodeVerificationMutationVariables,
    VerifyCodeForPasswordResetEmployerMutation,
    VerifyCodeForPasswordResetEmployerMutationVariables,
    VerifyEmployerExistenceMutation,
    VerifyEmployerExistenceMutationVariables,
} from "@/apollo/generated/types";
import { RESET_EMPLOYER_PASSWORD, VERIFY_EMPLOYER, VERIFY_RESET_CODE } from "@/apollo/graphql/mutations/auth";
import { Button, TextInput } from "@/components";
import ButtonLoader from "@/components/loaders/button";
import { classNames } from "@/utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";

interface Step {
    id: number;
    title: string;
    description: string;
}

const steps: Step[] = [
    {
        id: 1,
        title: "Verify Password",
        description: "Enter your current password",
    },
    {
        id: 2,
        title: "Authentication",
        description: "Enter OTP to authenticate",
    },
    {
        id: 3,
        title: "New Password",
        description: "Enter and confirm your new password",
    },
];

export default function PasswordChangeForm() {
    const user = useCurrentUser();
    const navigate = useAppNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [otp, setOtp] = useState<string[]>(new Array(4).fill(""));
    const otpRefs = [React.useRef<HTMLInputElement>(null), React.useRef<HTMLInputElement>(null), React.useRef<HTMLInputElement>(null), React.useRef<HTMLInputElement>(null)];

    const [verifyEmployerExistence, { loading: loadingSend }] = useMutation<VerifyEmployerExistenceMutation, VerifyEmployerExistenceMutationVariables>(VERIFY_EMPLOYER);
    const [verifyResetCode, { loading: loadingVerify }] = useMutation<VerifyCodeForPasswordResetEmployerMutation, VerifyCodeForPasswordResetEmployerMutationVariables>(VERIFY_RESET_CODE);
    const [resetPassword, { loading: loadingReset }] = useMutation<ResetPasswordAfterCodeVerificationMutation, ResetPasswordAfterCodeVerificationMutationVariables>(RESET_EMPLOYER_PASSWORD);

    const form = useFormik({
        initialValues: {
            currentPassword: "",
            code: "",
            newPassword: "",
            confirmPassword: "",
            token: "",
        },
        validationSchema: Yup.object().shape({
            currentPassword: Yup.string().min(8, "Password must be more than 8 characters").required("Current Password is required"),
            code: Yup.string().min(4, "Code should be 4 digits").required("OTP is required"),
            newPassword: Yup.string()
                .min(8, "Password must be more than 8 characters")
                .required("Password is required")
                .matches(/^(?=.*[a-z])/, "Must Contain at least One Lowercase Character")
                .matches(/^(?=.*[A-Z])/, "Must Contain at least One Uppercase Character")
                .matches(/^(?=.*[0-9])/, "Must Contain at least One Number")
                .matches(/^(?=.*[!@#$%^&*\\|/{}()<>:;[\]_\\-\\=?])/, "Must Contain at least One special case Character")
                .required("New password is required"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("newPassword"), undefined], "Passwords must match")
                .required("Confirm password is required"),
        }),
        onSubmit: (values) => {},
    });

    const handleOtpChange = (index: number, value: string) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (value && index < 3) {
            otpRefs[index + 1].current?.focus();
        }
        form.setFieldValue("code", newOtp.join(""));
    };

    const handleOtpKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            otpRefs[index - 1].current?.focus();
        }
    };

    const handleProceed = async () => {
        if (currentStep === 1) {
            // Call API to verify OTP
            if (_.isEmpty(form.errors.currentPassword) && !_.isEmpty(form.values.currentPassword)) {
                await verifyEmployerExistence({
                    variables: {
                        email: user?.email as string,
                    },
                })
                    .then(({ data }) => {
                        toast(JSON.stringify({ type: "success", title: "Code sent successfully" }));
                        setCurrentStep((prev) => prev + 1);
                    })
                    .catch(() => {
                        toast(JSON.stringify({ type: "error", title: "Something wrong happened" }));
                    });
            } else {
                form.setTouched({ currentPassword: true });
            }
        }
        if (currentStep === 2) {
            if (_.isEmpty(form.errors.code) && !_.isEmpty(form.values.code)) {
                await verifyResetCode({
                    variables: {
                        userId: user?.id || "",
                        code: form.values.code,
                    },
                })
                    .then(({ data }) => {
                        form.setFieldValue("token", data?.verifyCodeForPasswordResetEmployer?.token);
                        toast(JSON.stringify({ type: "success", title: "Code verified successfully" }));
                        setCurrentStep((prev) => prev + 1);
                    })
                    .catch(() => {
                        toast(JSON.stringify({ type: "error", title: "Something wrong happened" }));
                    });
            } else {
                form.setTouched({ code: true });
            }
        }
        if (currentStep === 3) {
            // Call API to verify OTP

            if (_.isEmpty(form.errors.newPassword) && _.isEmpty(form.errors.confirmPassword) && form.values.newPassword === form.values.confirmPassword && !_.isEmpty(form.values.newPassword)) {
                await resetPassword({
                    variables: {
                        newPassword: form.values.newPassword,
                        confirmedPassword: form.values.confirmPassword,
                    },
                    context: {
                        headers: {
                            authorization: form.values.token,
                        },
                    },
                })
                    .then(({ data }) => {
                        toast(JSON.stringify({ type: "success", title: "Password changed successfully" }));
                        clearAuth(); // Ensure clearAuth completes
                        navigate({
                            to: "/login",
                            search: { page: 1, pageSize: 10 },
                        }); // Navigate to login
                    })
                    .catch((err) => {
                        toast(JSON.stringify({ type: "error", title: "Something wrong happened" }));
                    });
            } else {
                form.setTouched({ newPassword: true, confirmPassword: true });
            }
        }
    };

    const handleCancel = () => {
        if (currentStep > 1) {
            setCurrentStep((prev) => prev - 1);
        }
        form.resetForm();
    };

    return (
        <div className="w-full  mx-auto p-6">
            <div className="space-y-6">
                <div className="border-b border-grey-200 pb-5 mb-5">
                    <h4 className=" font-semibold text-lg text-grey-900 mb-2">Password</h4>
                    <p className="text-xs font-medium leading-5  text-gray-500">Kindly complete the flow to change password</p>
                </div>

                <div className="flex gap-8 justify-between items-start max-w-5xl">
                    <div className="w-64 space-y-1 sm:block hidden rounded-2xl bg-slate-50 px-7 py-3">
                        {steps.map((step, index) => (
                            <div key={step.id} className="relative">
                                <div className={classNames("flex items-start gap-4 py-4", currentStep === step.id ? "text-primary" : "text-muted-foreground")}>
                                    <div className="relative">
                                        <div className={classNames("w-2 h-2 rounded-full mt-3", currentStep === step.id ? "bg-primary" : "bg-muted-foreground")} />
                                        {index < steps.length - 1 && <div className={classNames("absolute top-3 left-1 w-[2px] h-4 -translate-x-1/2", currentStep > step.id ? "bg-primary" : "bg-border")} />}
                                    </div>
                                    <div className="space-y-0.5">
                                        <div className="font-semibold text-xs">{step.title}</div>
                                        <div className="text-xs">{step.description}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="flex-1 space-y-4 mt-5">
                        {currentStep === 1 && (
                            <div className="space-y-4">
                                <div className="flex justify-start items-start gap-5">
                                    <label htmlFor="currentPassword" className="text-sm mt-1 font-medium">
                                        Current password
                                    </label>
                                    <div className="flex-1 max-w-96">
                                        <TextInput id="currentPassword" type="password" placeholder="**************" {...form} />
                                    </div>
                                </div>
                            </div>
                        )}

                        {currentStep === 2 && (
                            <div className="space-y-4">
                                <div className="space-y-2">
                                    <label htmlFor="otp" className="text-sm font-medium mb-3">
                                        <span>OTP Verification</span> (
                                        <span className="text-xs text-gray-500 font-medium">
                                            Sent to <span className="text-gray-600 ">{user?.email}</span>
                                        </span>
                                        )
                                    </label>
                                    <div className="flex justify-start items-center space-x-5 mb-6">
                                        {[0, 1, 2, 3].map((index) => (
                                            <input
                                                key={index}
                                                ref={otpRefs[index]}
                                                type="text"
                                                inputMode="numeric"
                                                maxLength={1}
                                                className="w-14 h-14 border-2 rounded bg-transparent outline-none text-center font-montserrat font-medium text-lg leading-5 tracking-[-2%] spin-button-none border-primary focus:border-primary focus:text-primary text-primary shadow-sm transition"
                                                value={otp[index]}
                                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                                onKeyDown={(e) => handleOtpKeyDown(index, e)}
                                            />
                                        ))}
                                    </div>
                                    {form.errors.code && form.touched.code && <div className="text-red-500 text-xs">{form.errors.code}</div>}
                                </div>
                            </div>
                        )}

                        {currentStep === 3 && (
                            <div className="space-y-4">
                                <div className="flex flex-col  justify-between items-start  gap-1">
                                    <label htmlFor="newPassword" className="text-sm mt-1 font-medium">
                                        New password
                                    </label>
                                    <div className="flex-1 md:max-w-96 w-full">
                                        <TextInput id="newPassword" type="password" placeholder="*********" {...form} />
                                    </div>
                                </div>
                                <div className="flex flex-col  justify-between items-start  gap-1">
                                    <label htmlFor="confirmPassword" className="text-sm mt-1 font-medium whitespace-nowrap">
                                        Confirm new password
                                    </label>
                                    <div className="flex-1 md:max-w-96 w-full">
                                        <TextInput id="confirmPassword" type="password" placeholder="*********" {...form} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex justify-end gap-4">
                    <div>
                        <Button variant="minimal" size="sm" onClick={handleCancel} disabled={currentStep === 1}>
                            Cancel
                        </Button>
                    </div>
                    <div>
                        <Button size="sm" onClick={handleProceed}>
                            {loadingReset || loadingSend || loadingVerify ? <ButtonLoader title=" Loading ..." /> : currentStep === 3 ? "Change Password" : "Proceed"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
