import React, { useEffect, useState } from "react";
import { useDropzone, FileError } from "react-dropzone";
import logoPlaceholder from "@/assets/images/compDlogo.png";
import upload from "@/assets/svg/upload.svg";
import { CiEdit } from "react-icons/ci";
import CompanyInfoModal from "./modals/company-info";
import CompanyContactModal from "./modals/company-contact";
import { Employer, UpdateCompanyProfilePictureMutation, UpdateCompanyProfilePictureMutationVariables } from "@/apollo/generated/types";
import { useUpdateEmployer, useUploadImage } from "@/hooks";
import { BarLoader } from "react-spinners";
import { useMutation } from "@apollo/client";
import { UPDATE_COMPANY_PROFILE } from "@/apollo/graphql/mutations/company";
import toast from "react-hot-toast";
import { hasPermission, PERMISSIONS } from "@/permissions";
import { useCurrentUser, useCurrentUserPermissions } from "@/apollo/cache/auth";
import { Show } from "@/components";

export interface UploadableFile {
    file: File;
    fieldName?: string;
    errors: FileError[];
    url?: string;
}

export default function Company({ user, currentUserPermissions }: { user: Employer; currentUserPermissions: string[] }) {
    const [showCompanyInfo, setShowCompanyInfo] = useState(false);
    const [loadingUpload, setLoadindUpload] = useState(false);
    const [showCompanyContact, setShowCompanyContact] = useState(false);
    const { refetch } = useUpdateEmployer();
    const { handleUpload, loading, error, image: logo, setImage } = useUploadImage();

    const [updateProfile, { loading: updateLoading }] = useMutation<UpdateCompanyProfilePictureMutation, UpdateCompanyProfilePictureMutationVariables>(UPDATE_COMPANY_PROFILE, { refetchQueries: ["GetEmployer"] });

    const company = user?.company;

    const onDropCompanyLogo = async (file: any) => {
        setLoadindUpload(true);
        if (file[0]) {
            await handleUpload(file[0]);
            setLoadindUpload(false);
        } else {
            toast(JSON.stringify({ type: "error", title: "Unable to process" }));
            setLoadindUpload(false);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onDropCompanyLogo,
        accept: { "image/*": [] },
    });

    const handleSave = async () => {
        await updateProfile({ variables: { companyLogo: logo, updateCompanyProfilePictureId: company?.id || "" } })
            .then(() => {
                toast(JSON.stringify({ type: "success", title: "Profile updated successfully" }));
                setImage("");
                refetch();
            })
            .catch(() => {
                toast(JSON.stringify({ type: "error", title: "Something wront happened" }));
            });
    };

    return (
        <div className="p-5 relative">
            <section className="w-full flex flex-col md:flex-row justify-between items-start mobile:gap-5 gap-10 mobile:p-4 p-8 ring-1 ring-gray-300 rounded-lg mb-5">
                <div className="">
                    <p className="font-montserrat text-sm/4 text-gray-800 mb-2.5">Company Logo</p>
                    <p className="font-montserrat text-xs/3 text-gray-500 mb-6">Upload logo to represent your company</p>
                    <div className="w-20 md:w-24 h-20 md:h-24 rounded-full shadow-lg bg-white overflow-hidden">
                        <img src={logo || company?.companyLogo || logoPlaceholder} alt="profile picture" className="object-contain " />
                    </div>
                    <div className="text-xs w-full flex justify-center items-start flex-col mt-5 space-y-2 ">
                        {loading ||
                            updateLoading ||
                            (loadingUpload && (
                                <p className="flex flex-col items-center">
                                    Loading .... <BarLoader color="#02733e" className="h-3 w-3 text-xs" />{" "}
                                </p>
                            ))}
                        {logo && (
                            <button className="ml-5 px-4 py-2 bg-primary-500 text-white rounded font-semibold" onClick={handleSave}>
                                Save
                            </button>
                        )}
                    </div>
                </div>
                <div
                    {...getRootProps({
                        className: "dropzone w-full md:max-w-md p-5 flex flex-col gap-4 justify-center items-center border border-dashed border-primary rounded-xl cursor-pointer bg-[#EBFFEB]",
                    })}>
                    <input {...getInputProps()} />
                    <img src={upload} alt="upload file" className="" />
                    <p className="text-sm text-[#0B0B0B]">Drag your file(s) to start uploading</p>
                    <div className="w-full md:max-w-xl flex items-center gap-x-2 my-2">
                        <div style={{ content: "" }} className="w-full h-[1px] bg-gray-200" />
                        <p className="text-gray-600 text-xs/5">OR</p>
                        <div style={{ content: "" }} className="w-full h-[1px] bg-gray-200" />
                    </div>
                    <button className="border bg-white border-primary px-4 py-2 rounded-xl font-semibold text-xs/5 text-primary">Browse files</button>
                </div>
            </section>

            <section className="w-full  p-4 md:p-8 ring-1 ring-gray-300 rounded-lg mb-5">
                <div className="flex justify-between gap-6 items-center">
                    <div className="">
                        <p className="font-montserrat text-sm/4 text-gray-800 mb-2.5">Company Information</p>
                        <p className="font-montserrat text-xs/3 text-gray-500">Make changes to your company details here</p>
                    </div>
                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.PROFILE_COMPANY_UPDATE)}>
                        <div
                            onClick={() => {
                                setShowCompanyInfo(true);
                            }}
                            className="w-fit font-montserrat flex items-center gap-2 px-3.5 py-2 rounded-lg cursor-pointer ring-1 ring-[#D0D5DD] shadow">
                            <CiEdit className="w-5 h-5 text-[#292D32] " aria-hidden="true" />
                            <span className="font-medium text-sm text-black">Edit</span>
                        </div>
                    </Show>
                </div>

                <div className="w-full  mt-5 flex justify-end">
                    <div className="w-full md:max-w-xl flex flex-col gap-4 items-end ">
                        <div className="w-full">
                            <p className="font-montserrat text-xs/5 text-gray-500 mb-2">Company Name</p>
                            <div className="ring-1 ring-gray-300 bg-gray-200 rounded-lg px-4 mobile:py-2.5  py-2">
                                <p className="font-montserrat text-xs/5 text-gray-800">{company?.name ?? "N/A"}</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <p className="font-montserrat text-xs/5 text-gray-500 mb-2">About Company</p>
                            <div className="ring-1 ring-gray-300 bg-gray-200 rounded-lg px-4 mobile:py-2.5  py-2">
                                <p className="font-montserrat text-xs/5 text-gray-800 ">{company?.about ?? "N/A"}</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <p className="font-montserrat text-xs/5 text-gray-500 mb-2">Year of Establishment</p>
                            <div className="ring-1 ring-gray-300 bg-gray-200 rounded-lg px-4 mobile:py-2.5  py-2">
                                <p className="font-montserrat text-xs/5 text-gray-800">{company?.yearOfEstablishment ?? "N/A"}</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <p className="font-montserrat text-xs/5 text-gray-500 mb-2">Company Type</p>
                            <div className="ring-1 ring-gray-300 bg-gray-200 rounded-lg px-4 mobile:py-2.5  py-2">
                                <p className="font-montserrat text-xs/5 text-gray-800">{company?.companyType ?? "N/A"}</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <p className="font-montserrat text-xs/5 text-gray-500 mb-2">Industry</p>
                            <div className="ring-1 ring-gray-300 bg-gray-200 rounded-lg px-4 mobile:py-2.5  py-2">
                                <p className="font-montserrat text-xs/5 text-gray-800">{company?.industry ?? "N/A"}</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <p className="font-montserrat text-xs/5 text-gray-500 mb-2">Address</p>
                            <div className="ring-1 ring-gray-300 bg-gray-200 rounded-lg px-4 mobile:py-2.5  py-2">
                                <p className="font-montserrat text-xs/5 text-gray-800">{company?.address ?? "N/A"}</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <p className="font-montserrat text-xs/5 text-gray-500 mb-2">Number of employees</p>
                            <div className="ring-1 ring-gray-300 bg-gray-200 rounded-lg px-4 mobile:py-2.5  py-2">
                                <p className="font-montserrat text-xs/5 text-gray-800">{company?.numOfEmployees ?? "0"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className=" w-full p-4 md:p-8 ring-1 ring-gray-300 rounded-lg ">
                <div className="flex justify-between mobile:gap-6 items-center">
                    <div className="">
                        <p className="font-montserrat text-sm/4 text-gray-800 mb-2.5">Company Contact</p>
                        <p className="font-montserrat text-xs/3 text-gray-500">Make changes to your company contacts here</p>
                    </div>
                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.PROFILE_COMPANY_CONTACT_UPDATE)}>
                        <div
                            onClick={() => {
                                setShowCompanyContact(true);
                            }}
                            className=" w-fit font-montserrat flex items-center gap-2 px-3.5 py-2 rounded-lg cursor-pointer ring-1 ring-[#D0D5DD] shadow">
                            <CiEdit className="w-5 h-5 text-[#292D32] " aria-hidden="true" />
                            <span className="font-medium text-sm text-black ">Edit</span>
                        </div>
                    </Show>
                </div>

                <div className="w-full mt-5 flex justify-end">
                    <div className="w-full md:max-w-xl flex flex-col gap-4 items-end ">
                        <div className="w-full">
                            <p className="font-montserrat text-xs/5 text-gray-500 mb-2">Company Email</p>
                            <div className="ring-1 ring-gray-300 bg-gray-200 rounded-lg px-4 mobile:py-2.5  py-2">
                                <p className="font-montserrat text-xs/5 text-gray-800">{company?.email ?? "N/A"}</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <p className="font-montserrat text-xs/5 text-gray-500 mb-2">Phone Number</p>
                            <div className="ring-1 ring-gray-300 bg-gray-200 rounded-lg px-4 mobile:py-2.5  py-2">
                                <p className="font-montserrat text-xs/5 text-gray-800">{company?.phone ?? "N/A"}</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <p className="font-montserrat text-xs/5 text-gray-500 mb-2">Website</p>
                            <div className="ring-1 ring-gray-300 bg-gray-200 rounded-lg px-4 mobile:py-2.5  py-2">
                                <p className="font-montserrat text-xs/5 text-gray-800">{company?.website ?? "N/A"}</p>
                            </div>
                        </div>
                        <div className="w-full space-y-3">
                            {company?.socials?.map((info) => (
                                <div>
                                    <p className="font-montserrat text-xs/5 text-gray-500 mb-2">{info?.name}</p>
                                    <div className="ring-1 ring-gray-300 bg-gray-200 rounded-lg px-4 mobile:py-2.5  py-2">
                                        <p className="font-montserrat text-xs/5 text-gray-800">{info?.link}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <CompanyInfoModal
                refetch={refetch}
                visible={showCompanyInfo && hasPermission(currentUserPermissions, PERMISSIONS.PROFILE_COMPANY_UPDATE)}
                data={company || undefined}
                onClose={() => {
                    setShowCompanyInfo(false);
                }}
            />
            <CompanyContactModal
                refetch={refetch}
                visible={showCompanyContact && hasPermission(currentUserPermissions, PERMISSIONS.PROFILE_COMPANY_CONTACT_UPDATE)}
                data={company || undefined}
                onClose={() => {
                    setShowCompanyContact(false);
                }}
            />
        </div>
    );
}
