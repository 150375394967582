// eslint-disable-next-line @typescript-eslint/no-var-requires
import { Link, useNavigate } from "react-location";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { GoDotFill } from "react-icons/go";
import toast from "react-hot-toast";
import { Button, Modal, Show } from "@/components";
import { GoogleAuthProvider, auth, signInWithPopup } from "@/config/firebase";
import TabButton from "./components/TabButton";
import { classNames, useUrlState } from "@/utils";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useFormik } from "formik";
import * as Yup from "yup";

import "react-international-phone/style.css";
import RespresentativeForm from "./forms/RespresentativeForm";
import CompanyForm from "./forms/CompanyForm";
import { useAppDispatch, useAppSelector } from "@/redux/hook";
import { clearCompany, clearRepresentative, selectOnboardingDetails, setCompany, setCompanyId, setRepresentative } from "@/redux/auth/onboardingSlice";

import logo from "@/assets/images/company-logo-sec.png";
import { LOGIN } from "@/constants/page-paths";
import { AuthType, BusinessType, CompanyType, CreateCompanyMutation, CreateCompanyMutationVariables, CreateEmployerMutation, CreateEmployerMutationVariables } from "@/apollo/generated/types";
import { CREATE_COMPANY, CREATE_EMPLOYER } from "@/apollo/graphql/mutations/auth";
import _ from "lodash";

export default function SignUp() {
    const [active, setActive] = useState("Company");
    const [socialAuthLoading, setSocialAuthLoading] = useState(false);
    const [modal, setModal] = useUrlState("modal");
    const [authType, setAuthType] = useState<AuthType>(AuthType.Email);
    const onboardingDetails = useAppSelector(selectOnboardingDetails);
    const [signupCompany, { loading: loadingCompany }] = useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CREATE_COMPANY);

    const [signupEmployer, { loading: loadingEmployer }] = useMutation<CreateEmployerMutation, CreateEmployerMutationVariables>(CREATE_EMPLOYER);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { ...representativeForm } = useFormik({
        initialValues: {
            fullname: "",
            email: "",
            position: "",
            phone: "",
            password: "",
            confirm_password: "",
            googleAuth: false,
        },
        validationSchema: Yup.object({
            fullname: Yup.string().required("Your fullname is required"),
            email: Yup.string().email("Email is invalid").required("Personal email is required"),
            position: Yup.string().required("Your position in the company is required"),
            phone: Yup.string().required("Your telephone is required"),
            password: Yup.string().min(8, "Password must be at least 8 characters long").required("Your password is required"),
            confirm_password: Yup.string()
                .oneOf([Yup.ref("password")], "Passwords must match")
                .required("Confirm Password is required"),
        }),
        onSubmit: async (values) => {
            dispatch(setRepresentative({ ...values }));
            if (_.isEmpty(onboardingDetails.company_id)) {
                await signupCompany({
                    variables: {
                        input: {
                            address: onboardingDetails.company_address,
                            businessType: onboardingDetails.business_type as BusinessType,
                            companyEmail: onboardingDetails.company_email,
                            companyType: onboardingDetails.company_type as CompanyType,
                            industry: onboardingDetails.company_industry,
                            companyPhone: onboardingDetails.company_phone,
                            name: onboardingDetails.company_name,
                        },
                    },
                })
                    .then(({ data }) => {
                        dispatch(setCompanyId(data?.createCompany?.id || ""));
                        return signupEmployer({
                            variables: {
                                input: {
                                    companyId: data?.createCompany?.id || "",
                                    email: values.email,
                                    fullName: values.fullname,
                                    password: values.password,
                                    phone: values.phone,
                                    position: values.position,
                                    authType: authType,
                                },
                            },
                        });
                    })
                    .then(() => {
                        dispatch(clearRepresentative());
                        dispatch(clearCompany());
                        companyForm.resetForm();
                        representativeForm.resetForm();
                        toast(
                            JSON.stringify({
                                type: "success",
                                title: "Congratulations on signing up. Now let’s help you find the right talent for your team",
                            })
                        );
                        setModal("signup_success");
                    })
                    .catch((err) => {
                        toast(
                            JSON.stringify({
                                type: "error",
                                title: "Something wrong happened. Please check company details and try again",
                            })
                        );
                    });
            }
            if (!_.isEmpty(onboardingDetails?.company_id)) {
                await signupEmployer({
                    variables: {
                        input: {
                            companyId: onboardingDetails.company_id || "",
                            email: values.email,
                            fullName: values.fullname,
                            password: values.password,
                            phone: values.phone,
                            position: values.position,
                            authType: authType,
                        },
                    },
                })
                    .then(() => {
                        dispatch(clearRepresentative());
                        dispatch(clearCompany());
                        companyForm.resetForm();
                        representativeForm.resetForm();
                        toast(
                            JSON.stringify({
                                type: "success",
                                title: "Onboarding was successful",
                            })
                        );
                        setModal("signup_success");
                    })
                    .catch(() => {
                        toast(
                            JSON.stringify({
                                type: "error",
                                title: "Something wrong happened. Please check representative details and try again ",
                            })
                        );
                    });
            }
        },
    });

    const handleSocialAuth = async () => {
        try {
            setSocialAuthLoading(true);

            const provider = new GoogleAuthProvider();

            const googleUser: any = await signInWithPopup(auth, provider);

            representativeForm.setFieldValue("fullname", googleUser.user.displayName);
            representativeForm.setFieldValue("email", googleUser.user.email);
            representativeForm.setFieldValue("password", googleUser.user.uid);
            representativeForm.setFieldValue("confirm_password", googleUser.user.uid);
            representativeForm.setFieldValue("phone", googleUser.user.phoneNumber === null ? "" : googleUser.user.phoneNumber);
            representativeForm.setFieldValue("googleAuth", true);
            setAuthType(AuthType.Google);
            setSocialAuthLoading(false);
        } catch (e) {
            toast(
                JSON.stringify({
                    type: "error",
                    title: "An error occurred while signing up with Google",
                })
            );
            setSocialAuthLoading(false);
        }
    };

    const { ...companyForm } = useFormik({
        initialValues: {
            business_type: "",
            company_name: "",
            company_type: "",
            company_industry: "",
            company_address: "",
            company_phone: "",
            company_email: "",
        },
        validationSchema: Yup.object({
            business_type: Yup.string().required("Please select the nature of your company"),
            company_name: Yup.string().required("Name of the company is required"),
            company_type: Yup.string().required("Please select a company type"),
            company_industry: Yup.string().required("Please select a company industry"),
            company_address: Yup.string().required("Address of company is required"),
            company_phone: Yup.string().required("Telephone of company is required "),
            company_email: Yup.string().email("Invalid email address").required("Email of company is required"),
        }),
        onSubmit: async (values) => {
            dispatch(setCompany({ ...values }));
            setActive("Representative");
        },
    });

    useEffect(() => {
        representativeForm.setValues({ ...onboardingDetails });
        companyForm.setValues({ ...onboardingDetails });
    }, []);

    return (
        <>
            <div className="w-full pt-10">
                <div className="w-full">
                    <div className="  text-center w-full">
                        <h1 className="text-2xl font-semibold  leading-loose">Create Employer Account</h1>
                        <p className="mt-2 text-sm text-gray-500 ">Discover top talents connect with the perfect candidate today</p>
                        <div className="mt-5 mb-5">
                            <p className="text-center text-sm  font-medium ">
                                Already have an account?
                                <span className=" ml-2 text-primary text-sm font-semibold underline">
                                    <Link to={LOGIN}> Sign in</Link>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="w-full  mt-10  space-x-1 flex flex-col">
                        <div className="flex items-center justify-center space-x-1">
                            <button
                                onClick={() => setActive("Company")}
                                className={classNames(active === "Company" || (companyForm.isValid && companyForm?.values.company_email) ? "border-primary-500  bg-green-50" : "border-gray-100", "h-10 w-10 border-2  rounded-full flex items-center justify-center ")}>
                                {companyForm.isValid && companyForm?.values.company_email ? <CheckIcon className={classNames("text-primary-500 rounded-full  h-5 w-5")} /> : <GoDotFill className={classNames("rounded-full  h-5 w-5 text-gray-300")} />}
                            </button>
                            <span className="border border-primary-500 w-28" />
                            <button
                                onClick={() => {
                                    companyForm.handleSubmit();
                                }}
                                className={classNames(active === "Representative" || (representativeForm.isValid && representativeForm?.values.email) ? "border-primary-500 bg-green-50" : "border-gray-100", "h-10 w-10 border-2  rounded-full flex items-center justify-center")}>
                                {representativeForm.isValid && representativeForm?.values.email ? (
                                    <CheckIcon className={classNames("text-primary-500 rounded-full  h-5 w-5")} />
                                ) : (
                                    <GoDotFill className={classNames(active === "Representative" ? "text-primary-500" : "text-gray-300", "rounded-full  h-5 w-5")} />
                                )}
                            </button>
                        </div>
                        <div className="w-full flex justify-center items-center  mt-2 ">
                            <TabButton active={active} title="Company" setActive={() => setActive("Company")} />
                            <span className="w-20" />
                            <TabButton active={active} title="Representative" setActive={() => companyForm.handleSubmit()} />
                        </div>
                    </div>
                </div>
                <div className="w-full flex justify-center items-start">
                    <Show if={active === "Representative"}>
                        <RespresentativeForm setActive={setActive} loadingAuth={socialAuthLoading} handleGoogleAuth={handleSocialAuth} form={representativeForm} loading={loadingEmployer || loadingCompany} />
                    </Show>
                    <Show if={active === "Company"}>
                        <CompanyForm form={companyForm} otherForm={representativeForm} loading={loadingCompany} setAuthType={setAuthType} />
                    </Show>
                </div>
            </div>
            {modal === "signup_success" && (
                <Modal
                    title={""}
                    hideActions={true}
                    size="lg"
                    open={modal === "signup_success"}
                    setOpen={(value) => {
                        navigate({
                            to: "/login",
                            search(prev) {
                                return { ...prev, modal: undefined };
                            },
                        });
                    }}>
                    <div className="w-full h-full  flex flex-col justify-center items-center space-y-3 py-10">
                        <img src={logo} className="h-14 w-14 rounded-full bg-cover object-cover" />
                        <h1 className="text-lg font-medium">Congratulations &#127881;</h1>
                        <p className="text-sm text-light text-center">
                            Welcome to Adjuma! Your employer account is <br />
                            ready to unlock
                        </p>
                        <div className="pt-5">
                            <Button
                                size="sm"
                                onClick={() => {
                                    navigate({
                                        to: "/login",
                                        search(prev) {
                                            return { ...prev, modal: undefined };
                                        },
                                    });
                                }}>
                                Post Job
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}
