import Wrapper from "@/components/wrapper";
import { FC, useState } from "react";
import Representative from "./profiles/representative";
import Company from "./profiles/company";
import { useCurrentUserPermissions } from "@/apollo/cache/auth";
import { Show } from "@/components";
import { hasPermission, PERMISSIONS } from "@/permissions";
import { useUpdateEmployer } from "@/hooks";
import { Employer } from "@/apollo/generated/types";

type Props = {};

const ProfilePage: FC<Props> = () => {
    const currentUserPermissions = useCurrentUserPermissions();
    const { refetch, employer } = useUpdateEmployer();
    const [currentProfile, setCurrentProfile] = useState<string>("representative");

    return (
        <Wrapper>
            <div className="relative w-full max-w-4xl">
                <div className="border-b border-gray-300 py-5 px-6">
                    <h1 className="font-montserrat font-semibold text-lg/5 tracking-[0.1px] text-black ">Profile</h1>
                </div>
                <header className="flex items-center gap-5 border-b border-gray-200 px-6 pt-4 transition-all duration-150 ease-in-out">
                    <button
                        onClick={() => {
                            setCurrentProfile("representative");
                        }}
                        className={`font-montserrat text-sm  cursor-pointer border-b-2 py-3 transition-all duration-150 ease-in-out ${currentProfile === "representative" ? "border-primary text-primary" : "border-transparent text-[#667085]"}`}>
                        Representative Profile
                    </button>
                    <Show if={hasPermission(currentUserPermissions, PERMISSIONS.PROFILE_COMPANY)}>
                        <button
                            onClick={() => {
                                setCurrentProfile("company");
                            }}
                            className={`font-montserrat text-sm  cursor-pointer border-b-2 py-3 transition-all duration-150 ease-in-out ${currentProfile === "company" ? "border-primary text-primary" : "border-transparent text-[#667085]"}`}>
                            Company Profile
                        </button>
                    </Show>
                </header>
                <div>
                    {currentProfile === "representative" && <Representative user={employer as Employer} refetch={refetch} />}
                    {currentProfile === "company" && <Company user={employer as Employer} currentUserPermissions={currentUserPermissions} />}
                </div>
            </div>
        </Wrapper>
    );
};

export default ProfilePage;
