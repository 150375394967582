type Props = {};

const CandidateGridLoader = (props: Props) => {
    return (
        <div className="whitespace-nowrap flex flex-col justify-center items-center  text-sm text-gray-500 border-b  flex-1   border-black/5 p-5 space-y-3 bg-white shadow">
            <div className="flex justify-start items-center space-x-5 ">
                <div className="bg-gray-200  h-10 w-10  rounded-full animate-pulse" />
                <div className="w-44 space-y-1">
                    <div className="bg-gray-200  h-5 w-4/6   animate-pulse rounded" />
                    <div className="bg-gray-200  h-3  w-full animate-pulse rounded" />
                </div>
            </div>
            <div className="bg-gray-200  h-10 w-full   animate-pulse rounded" />
            <div className="flex  w-full justify-between items-center space-x-5 pt-2 border-t ">
                <div className="bg-gray-200  h-6  w-1/3 animate-pulse rounded" />
                <div className="bg-gray-200  h-6  w-1/3 animate-pulse rounded" />
            </div>
        </div>
    );
};

export default CandidateGridLoader;
