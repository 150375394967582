import Wrapper from "@/components/wrapper";
import { FC, useEffect, useState } from "react";
import TabButton from "./components/CandidateTabButton";
import CandidateTable from "./components/CandidateTable";
import { useUrlState } from "@/utils";
import { useCurrentUser } from "@/apollo/cache/auth";
import { useCandidates, useJobLazyQuery, usePagination } from "@/hooks";
import { ApplicationStatus, CandidateQuery } from "@/apollo/generated/types";
import _ from "lodash";

type Props = {};

const CandidatesPage: FC<Props> = () => {
    const employer = useCurrentUser();
    const { limit, offset } = usePagination();
    const [activeFilter] = useUrlState("filter");
    const [active, setActive] = useState<ApplicationStatus | undefined>();
    const [search] = useUrlState("search");

    const { candidates, loading, refetch } = useCandidates({
        variables: {
            filter: {
                companyId: employer?.companyId ? { eq: employer?.companyId } : undefined,
                jobId: !_.isEmpty(activeFilter) ? { eq: activeFilter } : undefined,
                status: !_.isEmpty(active) ? { eq: active } : undefined,
                applicationDetails: {
                    firstName: search ? { like: search } : undefined,
                },
            },
            pagination: { limit: limit, offset: offset },
            sort: {},
        },
    });

    const { job, findJob } = useJobLazyQuery();

    useEffect(() => {
        if (activeFilter?.length > 3) {
            findJob({ variables: { jobId: activeFilter } });
        }
        refetch();
    }, [activeFilter, active]);
    return (
        <>
            <Wrapper>
                <div className="relative w-full overflow-hidden">
                    <div className="w-full h-full ">
                        <div className="flex justify-start items-center border-b w-full h-10 text-sm lg:space-x-10 space-x-5 overflow-scroll overflow-x-scroll no-scrollbar sticky top-0 z-30 px-5 pt-5">
                            <TabButton title={activeFilter?.length > 3 ? job?.title || "All" : "All"} active={active} setActive={setActive} value={undefined} quantity={candidates?.rows?.length || 0} />
                            <TabButton title={"Pending"} active={active} setActive={setActive} value={ApplicationStatus.Pending} quantity={candidates?.rows?.length || 0} />
                            <TabButton title={"Accepted"} active={active} setActive={setActive} value={ApplicationStatus.Accepted} quantity={candidates?.rows?.length || 0} />
                            <TabButton title={"Rejected"} active={active} setActive={setActive} value={ApplicationStatus.Rejected} quantity={candidates?.rows?.length || 0} />
                            <TabButton title={"Hired"} active={active} setActive={setActive} value={ApplicationStatus.Hired} quantity={candidates?.rows?.length || 0} />
                        </div>
                        <div className="mt-10 px-5 w-full">
                            <CandidateTable candidates={candidates as CandidateQuery} active={active} loading={loading} refetch={refetch} />
                        </div>
                    </div>
                </div>
            </Wrapper>
        </>
    );
};

export default CandidatesPage;
