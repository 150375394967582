import { useState } from "react";
import { useDropzone } from "react-dropzone";
import maleImage from "@/assets/images/male.jpeg";
import upload from "@/assets/svg/upload.svg";
import { CiEdit } from "react-icons/ci";
import RepresentativeModal from "./modals/representative";
import { Employer, UpdateEmployerMutation, UpdateEmployerMutationVariables } from "@/apollo/generated/types";
import { useUploadImage } from "@/hooks";
import { BarLoader } from "react-spinners";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { UPDATE_EMPLOYER } from "@/apollo/graphql/mutations/employer";

const Representative = ({ user, refetch }: { user: Employer; refetch: () => {} }) => {
    const [showRepModal, setShowRepModal] = useState(false);
    const [loadingUpload, setLoadindUpload] = useState(false);

    const [updateEmployer, { loading: updateLoading }] = useMutation<UpdateEmployerMutation, UpdateEmployerMutationVariables>(UPDATE_EMPLOYER, { refetchQueries: ["GetEmployer"] });
    const { handleUpload, image: profilePic, setImage } = useUploadImage();

    const onDropRepresentativePic = async (file: any) => {
        setLoadindUpload(true);
        if (file[0]) {
            await handleUpload(file[0]);
            setLoadindUpload(false);
        } else {
            toast(JSON.stringify({ type: "error", title: "Unable to process" }));
            setLoadindUpload(false);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onDropRepresentativePic,
        accept: { "image/*": [] },
    });

    const handleSave = async () => {
        await updateEmployer({ variables: { fullName: user?.fullName || "", email: user?.email || "", phone: user?.phone || "", position: user?.position || "", profilePicture: profilePic } })
            .then(() => {
                toast(JSON.stringify({ type: "success", title: "Profile updated successfully" }));
                setImage("");
                refetch();
            })
            .catch(() => {
                toast(JSON.stringify({ type: "error", title: "Something wront happened" }));
            });
    };
    return (
        <div className="p-5">
            <section className=" flex flex-col sm:flex-row justify-between items-start mobile:gap-5 gap-10 mobile:p-4 p-8 ring-1 ring-gray-300 rounded-lg mb-5">
                <div className="flex flex-col  items-start justify-center">
                    <p className="font-montserrat text-sm/4 text-gray-800 mb-2.5">Profile Photo</p>
                    <p className="font-montserrat text-xs/3 text-gray-500 mb-6">Upload profile photo to make changes</p>
                    <div className="w-20 sm:w-24 h-20 sm:h-24 rounded-full shadow-lg bg-white overflow-hidden">
                        <img src={profilePic || user?.profilePicture || maleImage} alt="profile picture" className="object-contain " />
                    </div>
                    <div className="text-xs w-full flex justify-center items-start flex-col mt-5 space-y-2">
                        {updateLoading || loadingUpload ? (
                            <p className="flex flex-col items-center">
                                Loading .... <BarLoader color="#02733e" className="h-3 w-3 text-xs" />{" "}
                            </p>
                        ) : (
                            <></>
                        )}
                        {profilePic && (
                            <button className="ml-5 px-4 py-2 bg-primary-500 text-white rounded font-semibold" onClick={handleSave}>
                                Save{" "}
                            </button>
                        )}
                    </div>
                </div>
                <div
                    {...getRootProps({
                        className: "dropzone w-full md:max-w-md p-5 flex flex-col gap-4 justify-center items-center border border-dashed border-primary rounded-xl cursor-pointer bg-[#EBFFEB]",
                    })}>
                    <input {...getInputProps()} />
                    <img src={upload} alt="upload file" className="" />
                    <p className="text-sm text-[#0B0B0B]">Drag your file(s) to start uploading</p>
                    <div className="w-[12.563rem] flex items-center gap-x-2 my-2">
                        <div style={{ content: "" }} className="w-full h-[1px] bg-slate-200" />
                        <p className="text-gray-600 text-xs/5">OR</p>
                        <div style={{ content: "" }} className="w-full h-[1px] bg-slate-200" />
                    </div>
                    <button className="border bg-white border-primary px-4 py-2 rounded-xl font-semibold text-xs/5 text-primary">Browse files</button>
                </div>
            </section>

            <section className="w-full p-4 sm:p-8 ring-1 ring-gray-300 rounded-lg">
                <div className="flex justify-between mobile:gap-6 items-center">
                    <div className="">
                        <p className="font-montserrat text-sm/4 text-gray-800 mobile:mb-2 mb-2.5">Personal Information</p>
                        <p className="font-montserrat text-xs/3 text-gray-500">Make changes to your profile details here</p>
                    </div>
                    <div
                        onClick={() => {
                            setShowRepModal(true);
                        }}
                        className="w-fit font-montserrat flex items-center gap-2 px-3.5 py-2 rounded-lg cursor-pointer ring-1 ring-[#D0D5DD] shadow">
                        <CiEdit className="w-5 h-5 text-[#292D32] " aria-hidden="true" />
                        <span className="font-medium text-sm text-black">Edit</span>
                    </div>
                </div>

                <div className="w-full mt-5 flex justify-end">
                    <div className="w-full md:max-w-xl flex flex-col gap-4 items-end ">
                        <div className="w-full">
                            <p className="font-montserrat text-xs/5 text-gray-500 mb-2">Full Name</p>
                            <div className="ring-1 ring-gray-300 bg-slate-200 rounded-lg px-4 mobile:py-2.5 py-2">
                                <p className="font-montserrat text-xs/5 text-gray-800">{user?.fullName ?? "N/A"}</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <p className="font-montserrat text-xs/5 text-gray-500 mb-2">Email</p>
                            <div className="ring-1 ring-gray-300 bg-slate-200 rounded-lg px-4 mobile:py-2.5 py-2">
                                <p className="font-montserrat text-xs/5 text-gray-800">{user?.email ?? "N/A"}</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <p className="font-montserrat text-xs/5 text-gray-500 mb-2">Phone Number</p>
                            <div className="ring-1 ring-gray-300 bg-slate-200 rounded-lg px-4 mobile:py-2.5 py-2">
                                <p className="font-montserrat text-xs/5 text-gray-800">{user?.phone ?? "N/A"}</p>
                            </div>
                        </div>
                        <div className="w-full">
                            <p className="font-montserrat text-xs/5 text-gray-500 mb-2">Position in Company</p>
                            <div className="ring-1 ring-gray-300 bg-slate-200 rounded-lg px-4 mobile:py-2.5 py-2">
                                <p className="font-montserrat text-xs/5 text-gray-800">{user?.position ?? "N/A"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {showRepModal && (
                <RepresentativeModal
                    refetch={refetch}
                    visible={showRepModal}
                    data={user}
                    onClose={() => {
                        setShowRepModal(false);
                    }}
                />
            )}
        </div>
    );
};

export default Representative;
